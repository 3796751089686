import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_REQUESTING_PLATFORM } from '@fingo/lib/graphql';
import { getLocalStorageVariable } from '@fingo/lib/apollo/helpers/local-storage';

const useGetRequestingPlatform = (onCompleted = () => {}) => {
  const requestingPlatformId = getLocalStorageVariable('requestingPlatformIdVar');
  const { data, loading, refetch, error } = useQuery(GET_REQUESTING_PLATFORM, {
    variables: { id: requestingPlatformId },
    onCompleted,
    onError: () => setTimeout(refetch, 1500),
  });
  useEffect(() => {
    if (!error && !loading && data?.getRequestingPlatform) {
      refetch();
    }
  }, [loading, data]);
  return data?.getRequestingPlatform;
};

export default useGetRequestingPlatform;
