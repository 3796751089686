import createTheme from '@mui/material/styles/createTheme';
import { esES as coreES } from '@mui/material/locale';
import { esES } from '@mui/x-data-grid';
import defaultPalette from '@fingo/lib/theme/palette';
import getPalette from './palette';
import { defaultBreakpoints, getBreakpoints } from './breakpoints';
import { defaultTypography, getTypography } from './typography';
import components from './components';

const createCustomTheme = (configString) => {
  try {
    const customConfig = JSON.parse(configString);
    const palette = getPalette(customConfig);
    const breakpoints = getBreakpoints(customConfig);
    const typography = getTypography(customConfig);
    return createTheme(
      { palette, breakpoints, typography, components },
      coreES,
      esES,
    );
  } catch {
    return createTheme(
      {
        palette: defaultPalette,
        breakpoints: defaultBreakpoints,
        typography: defaultTypography,
        components,
      },
      coreES,
      esES,
    );
  }
};

export default createCustomTheme;
