import merge from 'lodash/merge';
import defaultPalette from '@fingo/lib/theme/palette';

const getCustomPalette = (customConfig) => ({
  primary: {
    main: customConfig.color,
  },
});

const getPalette = (customConfig) => merge({}, defaultPalette, getCustomPalette(customConfig));

export default getPalette;
