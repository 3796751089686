const components = {
  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      body: {
        margin: 0,
        height: 'auto',
        '*::-webkit-scrollbar': {
          width: 18,
        },
        '*::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.gray.A300,
          borderRadius: 20,
          border: '6px solid transparent',
          backgroundClip: 'content-box',
          '&:hover': {
            backgroundColor: '#CCCCCC',
          },
        },
      },
      '#root': {
        height: '100%',
      },
    }),
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: 'none',
      },
      columnHeaders: {
        border: 'none',
      },
      row: ({ theme }) => ({
        backgroundColor: '#f9f9f9',
        verticalAlign: 'top',
        marginBottom: 8,
        borderRadius: 12,
        '&$selected': {
          backgroundColor: theme.palette.primary.A200,
        },
        '&$selected:hover': {
          backgroundColor: theme.palette.primary.A200,
        },
      }),
      cell: {
        border: 'none',
        overflow: 'auto !important',
        whiteSpace: 'initial !important',
        '&:focus': {
          outline: 'none',
        },
        '&:focus-within': {
          outline: 'none',
        },
      },
      footerContainer: {
        justifyContent: 'center',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        backgroundColor: '#F7F7F7',
        minHeight: 20,
        marginTop: 5,
      },
      indicator: {
        display: 'none',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: '#CCCCCC',
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: 1.1,
        marginLeft: 7,
        minHeight: 20,
        height: 20,
        maxWidth: 280,
        minWidth: 220,
        padding: 0.4,
        textAlign: 'center',
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        '&:first-of-type': {
          marginLeft: 0,
        },
        '&.Mui-selected': {
          backgroundColor: '#FFFFFF',
          color: theme.palette.primary.main,
        },
      }),
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: 0,
        [theme.breakpoints.up('xs')]: {
          minHeight: 0,
        },
      }),
    },
  },
  MuiContainer: {
    variants: [
      {
        props: { variant: 'body' },
        style: {
          margin: 0,
          padding: '0 !important',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        },
      },
    ],
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        padding: '8px 14px',
      },
      root: {
        minHeight: 30,
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.inputText,
        backgroundColor: theme.palette.inputBackground,
        border: `1px solid ${theme.palette.inputBorder}`,
        borderRadius: '10px',
        fontSize: '16px',
        height: '48px',
        '&:hover': {
          backgroundColor: theme.palette.inputBackground,
          borderBottom: `1px solid ${theme.palette.inputBorder}`,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.inputBackground,
        },
      }),
      input: ({ theme }) => ({
        fontSize: '16px',
        color: theme.palette.secondary,
      }),
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: 'containedReverse', color: 'primary' },
        style: ({ theme }) => ({
          backgroundColor: 'white',
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.gray.A100,
          },
        }),
      },
      {
        props: { variant: 'white' },
        style: ({ theme }) => ({
          backgroundColor: 'white',
          color: 'black',
          '&:hover': {
            backgroundColor: theme.palette.gray.A100,
          },
        }),
      },
      {
        props: { variant: 'link' },
        style: ({ theme }) => ({
          color: theme.palette.primary.main,
          ...theme.typography.body2,
          '&:hover': {
            backgroundColor: 'transparent',
            cursor: 'pointer',
            textDecoration: 'underline',
          },
        }),
      },
      {
        props: { variant: 'hoverHighlightButton' },
        style: ({ theme }) => ({
          padding: 2,
          width: '50%',
          height: 80,
          backgroundColor: '#F2F2F2',
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#FFFFFF',
          },
          fontSize: { xs: 12, md: 15 },
        }),
      },
      {
        props: { variant: 'paper' },
        style: {
          borderRadius: '4px',
          boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
        },
      },
      {
        props: { variant: 'dropDown' },
        style: ({ theme }) => ({
          justifyContent: 'flex-start',
          color: theme.palette.text.primary,
          fontSize: 12,
          padding: 0,
          '& .MuiButton-startIcon': {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            fontSize: 13,
          },
          '& .MuiButton-endIcon': {
            color: theme.palette.primary.main,
          },
        }),
      },
    ],
    styleOverrides: {
      root: {
        padding: '0 14px',
        borderRadius: 8,
      },
      label: {
        height: '100%',
      },
      sizeSmall: {
        padding: '8px 16px',
        height: 'min-content',
      },
    },
  },
};

export default components;
