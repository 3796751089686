/* eslint-disable implicit-arrow-linebreak */
import { formatDayMonthYear, formatRut } from '@fingo/lib/helpers';
import { nationalTaxServiceCredentialsDialogVar } from '@fingo/lib/apollo/reactive-variables/alerts';
import {
  BankAccountsDialog,
  CredentialProviderDialog,
  UploadDigitalCertificateDialog,
} from '../components/dialogs';

export const getCurrentCredentialProvider = (selectedCompany) =>
  selectedCompany?.invoiceProviderCredentials?.at(-1)
  || selectedCompany?.possibleCredentialProvider;

const formatInvoiceProviderUsername = (invoiceProvider) => {
  if (invoiceProvider?.authenticationType?.name === 'CERTIFICATE') {
    return {
      title: 'Certificado:',
      value: invoiceProvider?.certificate || 'N/D',
    };
  }
  return {
    title: 'Rut:',
    value: formatRut(invoiceProvider?.username) || 'N/D',
  };
};

export const hasRequestingPlatformAcceptedTerms = (
  company,
  requestingPlatform,
) => {
  if (!company || !requestingPlatform) return false;
  const { currentUserAcceptedTerms } = company;
  const { code } = requestingPlatform;
  const requestingPlatformAcceptedTerms = currentUserAcceptedTerms?.filter(
    (term) => term?.requestingPlatform?.code === code,
  );
  return requestingPlatformAcceptedTerms?.length > 0;
};

export const getSiiCredentials = (company, nationalTaxService) => {
  const { hasSiiCredentials = null, siiCredentials = [] } = company;
  const currentSiiCredentials = siiCredentials.at(-1);
  const credentials = hasSiiCredentials
    ? [
      {
        key: 'Rut empresa:',
        value: company.masterEntity.displayNationalIdentifier || 'N/D',
      },
      {
        key: 'Actualizado al:',
        value: formatDayMonthYear(currentSiiCredentials.updatedAt),
      },
    ]
    : [];
  return {
    id: 'sii-credentials',
    title: `Credenciales ${nationalTaxService}`,
    subtitle: `Ingresa las credenciales del ${nationalTaxService} de tu empresa de forma segura, 
      para sincronizar con Fingo la información de tus facturas y cesiones.`,
    isValid: hasSiiCredentials,
    toggle: () =>
      nationalTaxServiceCredentialsDialogVar({
        open: true,
        autocomplete: true,
      }),
    credentials,
    errorMessage: `Sin credenciales ${nationalTaxService}`,
  };
};

export const getInvoiceProviderCredentials = (company) => {
  const {
    hasInvoiceProviderCredentials = null,
    invoiceProviderCredentials = [],
  } = company;
  const currentInvoiceProviderCredentials = invoiceProviderCredentials.at(-1);
  const credentials = hasInvoiceProviderCredentials
    ? [
      {
        key: 'Facturador:',
        value:
            currentInvoiceProviderCredentials.credentialProvider.name || 'N/D',
      },
      formatInvoiceProviderUsername(currentInvoiceProviderCredentials),
      {
        key: 'Actualizado al:',
        value: formatDayMonthYear(
          currentInvoiceProviderCredentials.updatedAt,
        ),
      },
    ]
    : [];
  return {
    id: 'invoice-provider',
    title: 'Credenciales del facturador',
    subtitle: `Elige tu facturador y sube tus credenciales para realizar 
      tus cesiones por dentro de la plataforma, y que así no tengas que salir de Fingo.`,
    isValid: hasInvoiceProviderCredentials,
    DialogComponent: CredentialProviderDialog,
    credentials,
    errorMessage: 'Sin credenciales del facturador',
  };
};

export const getDigitalCretificateCredentials = (company) => {
  const { hasDigitalCertificate = null, currentDigitalCertificate = null } = company;
  const hasExpirationDate = !!currentDigitalCertificate?.expireDatetime;
  const credentials = hasDigitalCertificate
    ? [
      {
        key: 'Certificado:',
        value: currentDigitalCertificate.certificate || 'N/D',
      },
      {
        key: 'Actualizado al:',
        value: formatDayMonthYear(currentDigitalCertificate.updatedAt),
      },
      hasExpirationDate && {
        title: 'Válido hasta el:',
        value:
            formatDayMonthYear(currentDigitalCertificate.expireDatetime)
            || 'N/D',
      },
    ]
    : [];
  return {
    id: 'digital-certificate',
    title: 'Certificado digital',
    subtitle: `Carga aquí el certificado digital del Representante Legal de tu 
      empresa para que puedas ceder facturas y contar con más seguridad en las operaciones.`,
    isValid: company?.hasDigitalCertificate,
    DialogComponent: UploadDigitalCertificateDialog,
    credentials,
    errorMessage: 'Sin certificado digital',
  };
};

export const getBankAccountCredentials = (company) => {
  const { hasBankAccount = null, bankAccounts = [] } = company;
  const currentBankAccountCredentials = bankAccounts
    .filter((a) => a?.active)
    .at(-1);
  const credentials = hasBankAccount
    ? [
      {
        key: 'Banco:',
        value: currentBankAccountCredentials.bankName || 'N/D',
      },
      {
        key: 'Nro cuenta:',
        value: currentBankAccountCredentials.accountNumber || 'N/D',
      },
      {
        key: 'Actualizado al:',
        value:
            formatDayMonthYear(currentBankAccountCredentials.updatedAt)
            || 'N/D',
      },
    ]
    : [];

  return {
    id: 'bank-account',
    title: 'Cuentas bancarias',
    subtitle: `Ingresa tus datos bancarios para que podamos depositarte. Puedes 
      ingresar múltiples cuentas y seleccionar la que más te acomode al momento de girar.`,
    isValid: company?.hasBankAccount,
    DialogComponent: BankAccountsDialog,
    credentials,
    errorMessage: 'Sin cuentas bancarias',
  };
};
