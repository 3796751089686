import { useQuery, useReactiveVar } from '@apollo/client';
import dayjs from '@fingo/lib/config/dayjs';
import React, { useMemo } from 'react';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import { BulletList } from '@fingo/lib/components/lists';
import { SelectBankAccount } from '@fingo/lib/components/selects';
import { FINGO_INFO } from '@fingo/lib/constants/fingo-data';
import { MY_OFFERS } from '@fingo/lib/graphql';
import { formatDayMonthYearSlash } from '@fingo/lib/helpers';

const useSkualoOfferShoppingCart = ({ bankId, setBankId }) => {
  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);
  const invoiceIds = shoppingCartOffers.map(({ invoiceId }) => invoiceId);
  const { data: offers, loading } = useQuery(MY_OFFERS, {
    variables: { id_In: invoiceIds, first: 100 },
    skip: !invoiceIds.length,
  });

  const invoicesFormated = useMemo(
    () => offers?.invoices.edges.map(
      ({ node }) => `Folio ${node.folio} - Fecha de pago: ${formatDayMonthYearSlash(
        dayjs(
          shoppingCartOffers.find(({ invoiceId }) => invoiceId === node.id)
            .dateToPay,
        ),
      )}`,
    ),
    [offers],
  );

  const paragraphs = useMemo(
    () => [
      {
        id: 1,
        title:
          'Dirígete al módulo de cesiones en Skualo, este se encuentra en Operaciones > Factoring > Cesiones',
        content: null,
      },
      {
        id: 2,
        title:
          'Selecciona la cuenta y Auxiliar correspondiente, persiona el botón Obtener y visualiza los documentos disponibles para cesión. Antes de presionar Agregar, selecciona los siguientes documentos:',
        content: <BulletList list={invoicesFormated} />,
      },
      {
        id: 3,
        title:
          'Para ceder a Fingo debes ingresar los siguientes datos de Cesionario:',
        content: <BulletList list={FINGO_INFO} />,
      },
      {
        id: 4,
        title:
          'Luego de presionar Ceder, el SII te enviará el resultado de la cesión por correo electrónico.',
        content: null,
      },
      {
        id: 5,
        title: 'Selecciona la cuenta del banco a la que se girará el dinero:',
        content: (
          <SelectBankAccount bankId={bankId} setBankId={setBankId} hideText />
        ),
      },
      {
        id: 6,
        title:
          'Una vez que las facturas estén cedidas, confirma tu cesión haciendo click en el botón "¡Operar con Fingo!"',
        content: null,
      },
    ],
    [offers, bankId],
  );

  return { paragraphs, loading };
};

export default useSkualoOfferShoppingCart;
