import React from 'react';
import { cleanPreOfferShoppingCart } from '@fingo/lib/apollo/helpers/shopping-cart';
import { closeShoppingCart } from '@fingo/lib/helpers';
import { FactoringOperationSummary } from '@fingo/lib/components/shopping-cart';
import { useEvaluatePreoffers, useShoppingCart, useStepper } from '@fingo/lib/hooks';
import PreofferSuccessMessage from '../components/shopping-carts/PreofferShoppingCart/PreofferSuccessMessage';

const usePreofferShoppingCartSteps = () => {
  const { currentStep, goNextStep, goBackStep } = useStepper();
  const { shoppingCartData } = useShoppingCart({ cartType: 'available-factoring' });
  const { requestPreoffer: evaluatePreoffers, loading } = useEvaluatePreoffers();
  const preofferShoppingSteps = [
    {
      Component: <FactoringOperationSummary shoppingCartData={shoppingCartData} />,
      onBack: null,
      onNext: () => {
        evaluatePreoffers();
        goNextStep();
      },
      title: 'Resumen de operación',
      loading: false,
    },
    {
      Component: <PreofferSuccessMessage />,
      onBack: null,
      onNext: () => {
        cleanPreOfferShoppingCart();
        closeShoppingCart();
      },
      title: '¡Estamos listos con la evaluación!',
      loading,
      nextButtonText: 'Cerrar',
    },
  ];
  return { preofferShoppingSteps, currentStep, goNextStep, goBackStep };
};

export default usePreofferShoppingCartSteps;
