import React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import client from '@fingo/lib/apollo';
import TranslationProvider from '@fingo/lib/components/translation/TranslationProvider';
import App from './App';

const FactoringWidget = () => (
  <ApolloProvider client={client}>
    <TranslationProvider>
      <App />
    </TranslationProvider>
  </ApolloProvider>
);

render(<FactoringWidget />, document.getElementById('root'));
