import React, { useCallback, useMemo, useState } from 'react';
import Onboarding from '../components/home/Onboarding';
import NationalTaxServiceCredentials from '../components/home/NationalTaxServiceCredentials';

const useHomeSteps = () => {
  const [activeStep, setActiveStep] = useState(0);

  const goNextStep = useCallback(() => {
    setActiveStep(activeStep + 1);
  }, []);

  const steps = useMemo(
    () => [
      { id: 'onboarding', Component: <Onboarding goNextStep={goNextStep} /> },
      {
        id: 'national-tax-service-credentials',
        Component: <NationalTaxServiceCredentials />,
      },
    ],
    [],
  );

  return [steps, activeStep, goNextStep];
};

export default useHomeSteps;
